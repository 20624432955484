import React, { useEffect, useState } from "react";
import { about } from "../../data";
import Headerhome from "../Headerhome";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";
import Image1 from "../../assets/pyramids-giza.jpg";
import Image2 from "../../assets/Musium.jpg";
import Image3 from "../../assets/sinia.webp";
import Image4 from "../../assets/OIP.jpg";
import Image5 from "../../assets/image9.jpg";
import Image6 from "../../assets/image7.jpg";
import axios from "axios";
import "./popular.css";

const languages = [
  { code: "EN", lang: "English", Id: 1 },
  { code: "IT", lang: "Italy", Id: 2 },
];

const Popular = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );
  const [otherLanguageData, setOtherLanguageData] = useState([]);

  const { t } = useTranslation();

  const fetchOtherLanguageData = async () => {
    try {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      const response = await axios.get("https://tourism-api.basilalasadi.com/api/Languages");
      setOtherLanguageData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  useEffect(() => {
    fetchOtherLanguageData();
    Aos.init();
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <section className="popular">
      <Headerhome title={t("headerHomePopular.title")}>
        {t("headerHomePopular.paragraph")}
      </Headerhome>
      <div className="popular-container container">
        <div className="text-box">
          <div className="row popular-box">
            <div className="col-lg-6 col-md-6">
              <div
                className="text-box"
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="900"
                data-aos-delay="200"
              >
                <h1>{t("popular.Destination1.title")}</h1>
                <p> {t("popular.Destination1.description")}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="img-box">
                <img
                  src={Image1}
                  alt={Image1}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="900"
                  data-aos-delay="200"
                />
                <img
                  className="img2"
                  src={Image2}
                  alt={Image2}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="900"
                  data-aos-delay="200"
                />
              </div>
            </div>
          </div>
          <div className="row popular-box">
            <div className="col-lg-6 col-md-6">
              <div
                className="text-box"
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="900"
                data-aos-delay="200"
              >
                <h1> {t("popular.Destination2.title")}</h1>
                <p> {t("popular.Destination2.description")}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="img-box">
                <img
                  src={Image3}
                  alt={Image3}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="900"
                  data-aos-delay="200"
                />
                <img
                  className="img2"
                  src={Image4}
                  alt={Image4}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="900"
                  data-aos-delay="200"
                />
              </div>
            </div>
          </div>
          <div className="row popular-box">
            <div className="col-lg-6 col-md-6">
              <div
                className="text-box"
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="900"
                data-aos-delay="200"
              >
                <h1> {t("popular.Destination3.title")}</h1>
                <p> {t("popular.Destination3.description")}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="img-box">
                <img
                  src={Image5}
                  alt={Image5}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="900"
                  data-aos-delay="200"
                />
                <img
                  className="img2"
                  src={Image6}
                  alt={Image6}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="900"
                  data-aos-delay="200"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Popular;
