import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import { RiPriceTag3Line } from "react-icons/ri";
import { CiTimer } from "react-icons/ci";
import Slider from "react-slick";
import "./tripsDetails.css";

const languages = [
  { code: "EN", lang: "English", Id: 1 },
  { code: "IT", lang: "Italy", Id: 2 },
];

const TripsDetails = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [addedTrips, setAddedTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );
  const [otherLanguageData, setOtherLanguageData] = useState([]);
  const params = useParams();

  const fetchOtherLanguageData = async () => {
    try {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      const response = await axios.get("https://tourism-api.basilalasadi.com/api/Languages");
      setOtherLanguageData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  const getTripsDetails = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        {
          languageId: selectedLanguageObject.Id,
          TripId: params.servicesId,
        }
      );
      setAddedTrips(response.data.Items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  const fetchGalleryImages = async () => {
    const url = `https://tourism-api.basilalasadi.com/api/Trip/GetTripImages?tripId=${params.servicesId}`;
    try {
      const response = await axios.get(url);
      setGalleryImages(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  useEffect(() => {
    getTripsDetails();
    fetchOtherLanguageData();
    fetchGalleryImages();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="tripsDetails">
        {addedTrips.map((addedTrip) => {
          return (
            <video
              className="video-container"
              autoPlay
              muted
              loop
              key={addedTrip.TripId}
            >
              <source src={addedTrip.MainVideoUrl} type="video/mp4" />
            </video>
          );
        })}
        {loading ? (
          <div className="spinner-container">
            <FadeLoader color="#2C834E" />
          </div>
        ) : (
          <div className="container tripsDetails-container">
            <div className="gallery">
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : (
                <Slider {...settings}>
                  {galleryImages.map((image) => (
                    <div className="slider-container" key={image.Id}>
                      <div>
                        <img src={image.ImagePath} alt="ImagePath" />
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
            {addedTrips.map((addedTrip) => {
              return (
                <div className="tripInfo" key={addedTrip.TripId}>
                  <h4>{addedTrip.Location}</h4>
                  <h1>{addedTrip.Title}</h1>
                  <div className="details">
                    <div className="sm-box">
                      <div className="icon">
                        <RiPriceTag3Line />
                      </div>
                      <div>
                        <small>From</small>
                        <h6 className="price">${addedTrip.CostPerPerson}</h6>
                      </div>
                    </div>
                    <div className="sm-box">
                      <div className="icon">
                        <CiTimer />
                      </div>
                      <div>
                        <small>From</small>
                        <h6>{addedTrip.FromDate}</h6>
                      </div>
                    </div>
                    <div className="sm-box">
                      <div className="icon">
                        <CiTimer />
                      </div>
                      <div>
                        <small>To</small>
                        <h6>{addedTrip.ToDate}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="tourPref">
                    <h3>About this tour</h3>
                    <p>{addedTrip.Description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </>
  );
};

export default TripsDetails;
