import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Trips_Logo-removebg.png";
import { AiOutlineClose } from "react-icons/ai";
import LanguageSelector from "../languageSelector/LanguageSelector";
import "./topbar.css";

const Topbar = () => {
  const [isNavShowing, setisNavShowing] = useState(false);

  return (
    <div className="topbar nav-container">
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <Link
            className="navbar-brand logo"
            to="/"
            onClick={() => setisNavShowing(false)}
          >
            <img src={Logo} alt="Trips Logo" />
          </Link>
          <button
            onClick={() => setisNavShowing((prev) => !prev)}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {isNavShowing ? (
              <AiOutlineClose />
            ) : (
              <span className="navbar-toggler-icon"></span>
            )}
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <AiOutlineClose className="close" />
              <li className="nav-item">
                <Link
                  className=" nav-link active p-2"
                  aria-current="page"
                  to="/dashboard/tripsControl"
                >
                  Add Trips
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-2" to="/dashboard/addedTrips">
                  Trips
                </Link>
              </li>
              <li>
                <LanguageSelector />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Topbar;
