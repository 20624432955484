import React from "react";
import Header from "../../component/header/Header";
import Popular from "../../component/popular/Popular";
import Trips from "../../component/trips/Trips";
import "./home.css";
import TravelWithUs from "../../component/whyTravelWithUs/TravelWithUs";

const Home = () => {
  return (
    <>
      <>
        <Header />
        <Trips />
        <Popular />
        <TravelWithUs />
      </>
    </>
  );
};

export default Home;
