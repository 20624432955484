import React from "react";
import { FaFacebookF, FaTiktok, FaPhoneAlt } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { BsYoutube, BsInstagram } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import "./footer.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="footer">
      <div className="container text-light">
        <div className="trippy d-flex justify-content-between align-items-center pb-5"></div>
        <div className="links ">
          <div className="project">
            <h2> {t("footer.navigation")}</h2>
            <h5>{t("footer.about")}</h5>
            <h5>{t("footer.services")}</h5>
            <h5>{t("footer.contact")}</h5>
          </div>
          <div className="community">
            <h2>{t("footer.destinations")}</h2>
            <h5>{t("footer.pyramids")}</h5>
            <h5>{t("footer.siniaDesert")}</h5>
            <h5>{t("footer.sharmElshiekh")}</h5>
          </div>
          <div className="help">
            <h2>{t("footer.help")}</h2>
            <h5>{t("footer.support")}</h5>
            <h5>{t("footer.troubleshooting")}</h5>
            <h5>{t("footer.contactus")}</h5>
          </div>
          <div className="others">
            <h2>{t("footer.contactus")}</h2>
            <h5>
              <a
                href="https://www.google.com/maps/place/Sharm+ElShiekh,+Egypt"
                target="__blank"
              >
                <HiLocationMarker /> Sharm El Sheik, Egypt
              </a>
            </h5>
            <h5>
              <MdEmail />
              <a
                href="mailto:salahmobark73@gmail.com?subject=Your Subject Here&body=Your Message Here"
                target="__blank"
              >
                salahmobark73@gmail.com
              </a>
            </h5>
            <h5>
              <FaPhoneAlt />
              <a
                href="https://api.whatsapp.com/send?phone=+201151115204&text=Hello%2C%20I'm%20interested%20in%20your%20product"
                target="__blank"
              >
                +201151115204
              </a>
            </h5>
          </div>
        </div>
        <div className="copyright">
          <p className="agencyRight">{t("footer.copyright")}</p>
          <div className="social">
            <a
              href="https://www.facebook.com/profile.php?id=100088919932881"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/mobarak.salah?igsh=MWtweWFveTZkZGZidQ=="
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <BsInstagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCK-Wq5K_LonTMAHZBdj2OIA"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <BsYoutube />
            </a>
            <a
              href="https://www.tiktok.com/@salah.mobarak2203?_t=8lZ0E51qYDZ&_r=1"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
