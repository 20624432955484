import React, { Fragment, useEffect, useState } from "react";
import Headerimage from "../../assets/night.jpg";
import Headpage from "../../component/Headpage";
import Headerhome from "../../component/Headerhome";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";
import { CiLocationOn } from "react-icons/ci";
import "./services.css";

const languages = [
  { code: "EN", lang: "English", Id: 1 },
  { code: "IT", lang: "Italy", Id: 2 },
];

const Services = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [otherLanguageData, setOtherLanguageData] = useState([]);

  const [addedTrips, setAddedTrips] = useState([]);

  const fetchOtherLanguageData = async () => {
    try {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      const response = await axios.get("https://tourism-api.basilalasadi.com/api/Languages");
      setOtherLanguageData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  const getAllAddedTrips = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        { languageId: selectedLanguageObject.Id }
      );
      setAddedTrips(response.data.Items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  useEffect(() => {
    fetchOtherLanguageData();
    getAllAddedTrips();
    Aos.init();
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <>
      <Headpage
        title={t("headerHomeServices.title")}
        image={Headerimage}
      ></Headpage>
      <section className="services">
        <Headerhome title={t("headerHomeTrips.title")}>
          {t("headerHomeTrips.paragraph")}
        </Headerhome>
        {loading ? (
          <div className="spinner-container">
            <FadeLoader color="#2C834E" />
          </div>
        ) : (
          <div className="container trips-container">
            {addedTrips.map((addedTrip) => {
              return (
                <div
                  className="card"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-delay="200"
                  data-aos-duration="900"
                  key={addedTrip.TripId}
                >
                  <img
                    src={addedTrip.MainImageUrl}
                    className="card-img-top"
                    alt={"recentTrips"}
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      <CiLocationOn /> {addedTrip.Location}
                    </h5>
                    <h4 className="card-title">{addedTrip.Title}</h4>
                    <p className="card-text">{addedTrip.Description}</p>
                    <small className="card-text d-block mb-4">
                      From <span>${addedTrip.CostPerPerson}</span> Per Person
                    </small>
                    <Link
                      to={`/services/${addedTrip.TripId}`}
                      className="details-btn"
                    >
                      {t("recentTrips.trip1.button")}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </>
  );
};

export default Services;
