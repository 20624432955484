import React from "react";
import myVideo from "../../assets/Header Video.mp4";
import { useTranslation } from "react-i18next";

import "./header.css";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="overlay"></div>
      <div className="header-container container">
        <video className="video-container" autoPlay muted loop>
          <source src={myVideo} type="video/mp4" />
        </video>
        <div className="text-info">
          <h1> {t("header.head1")}</h1>
          <h5>{t("header.paragraph")}</h5>
          <a
            href="https://api.whatsapp.com/send?phone=+201151115204&text=Hello%2C%20I'm%20interested%20in%20your%20product"
            target="__blank"
          >
            <button>{t("header.button")}</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
