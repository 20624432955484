import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { IoMdContact } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Trips_Logo-removebg.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../languageSelector/LanguageSelector";
import axios from "axios";
import "./navbar.css";

const languages = [
  { code: "EN", lang: "English", Id: 1 },
  { code: "IT", lang: "Italy", Id: 2 },
];

const Navbar = (navbar) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isNavShowing, setisNavShowing] = useState(false);
  const [data, setdata] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );
  const [otherLanguageData, setOtherLanguageData] = useState([]);

  const fetchOtherLanguageData = async () => {
    try {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      const response = await axios.get("https://tourism-api.basilalasadi.com/api/Languages");
      setOtherLanguageData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  const submitLanguageValue = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        { languageId: selectedLanguageObject.Id }
      );
      setdata(response.data.items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    fetchOtherLanguageData();
    submitLanguageValue();
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  // return (
  //   <div className="container nav-container">
  //     <nav className="navbar navbar-expand-lg bg-light">
  //       <div className="container-fluid">
  //         <Link
  //           className="navbar-brand logo"
  //           to="/"
  //           onClick={() => setisNavShowing(false)}
  //         >
  //           <img src={Logo} alt="Trips Logo" />
  //         </Link>
  //         <button
  //           onClick={() => setisNavShowing((prev) => !prev)}
  //           className="navbar-toggler"
  //           type="button"
  //           data-bs-toggle="collapse"
  //           data-bs-target="#navbarSupportedContent"
  //           aria-controls="navbarSupportedContent"
  //           aria-expanded="false"
  //           aria-label="Toggle navigation"
  //         >
  //           {isNavShowing ? (
  //             <AiOutlineClose />
  //           ) : (
  //             <span className="navbar-toggler-icon"></span>
  //           )}
  //         </button>
  //         <div className="collapse navbar-collapse" id="navbarSupportedContent">
  //           <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
  //             {data.map((navbar) => (
  //               <Fragment key={navbar.Key}>
  //                 <AiOutlineClose className="close" />
  //                 <li className="nav-item">
  //                   <Link className="nav-link p-2" to={navbar.Link}>
  //                     {navbar.Icon && <navbar.Icon className="icon" />}
  //                     {navbar.Value}
  //                   </Link>
  //                 </li>
  //               </Fragment>
  //             ))}
  //             {!localStorage.getItem("token") && (
  //               <li className="nav-item">
  //                 <Link className="nav-link sign" to="/login">
  //                   {t("navbar.log in")}
  //                 </Link>
  //               </li>
  //             )}
  //             {!localStorage.getItem("token") && (
  //               <li className="nav-item">
  //                 <Link className="nav-link sign" to="/signup">
  //                   {t("navbar.sign up")}
  //                 </Link>
  //               </li>
  //             )}
  //             {localStorage.getItem("token") && (
  //               <Fragment>
  //                 <li className="nav-item">
  //                   <Link className="nav-link p-2" to="/dashboard/tripsControl">
  //                     {t("navbar.dashboard")}
  //                   </Link>
  //                 </li>
  //                 <li className="nav-item">
  //                   <Link
  //                     className="nav-link sign"
  //                     to="/login"
  //                     onClick={logout}
  //                   >
  //                     {t("navbar.log out")}
  //                   </Link>
  //                 </li>
  //               </Fragment>
  //             )}
  //             <li className="nav-item">
  //               <LanguageSelector />
  //             </li>
  //             <li>
  //               <select onChange={changeLanguage} value={selectedLanguage}>
  //                 {otherLanguageData.map((lang) => (
  //                   <option key={lang.Id} value={lang.LanguageCode}>
  //                     {lang.LanguageCode}
  //                   </option>
  //                 ))}
  //               </select>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </nav>
  //   </div>
  // );

  return (
    <>
      <div className="container nav-container">
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <Link
              className="navbar-brand logo"
              to="/"
              onClick={() => setisNavShowing(false)}
            >
              <img src={Logo} alt="Trips Logo" />
            </Link>
            <button
              onClick={() => setisNavShowing((prev) => !prev)}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {isNavShowing ? (
                <AiOutlineClose />
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {!localStorage.getItem("token") ? (
                <ul
                  className="navbar-nav ms-auto mb-2 mb-lg-0"
                  key={navbar.Key}
                >
                  <AiOutlineClose className="close" />
                  <li className="nav-item">
                    <Link
                      className=" nav-link active p-2"
                      aria-current="page"
                      to="/"
                    >
                      <AiOutlineHome className="icon active" />
                      {t("navbar.home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/about">
                      <BsFillInfoCircleFill className="icon" />
                      {t("navbar.about")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/services">
                      <MdOutlineHomeRepairService className="icon" />
                      {t("navbar.service")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/contact">
                      <IoMdContact className="icon" />
                      {t("navbar.contact")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sign" to="/login">
                      {t("navbar.log in")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sign" to="/signup">
                      {t("navbar.sign up")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <LanguageSelector />
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <AiOutlineClose className="close" />
                  <li className="nav-item">
                    <Link
                      className=" nav-link active p-2"
                      aria-current="page"
                      to="/"
                    >
                      <AiOutlineHome className="icon active" />
                      {t("navbar.home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/about">
                      <BsFillInfoCircleFill className="icon" />
                      {t("navbar.about")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/services">
                      <MdOutlineHomeRepairService className="icon" />
                      {t("navbar.service")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/contact">
                      <IoMdContact className="icon" />
                      {t("navbar.contact")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link p-2" to="/dashboard/tripsControl">
                      <IoMdContact className="icon" />
                      {t("navbar.dashboard")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link sign"
                      to="/login"
                      onClick={logout}
                    >
                      {t("navbar.log out")}
                    </Link>
                  </li>
                  <li>
                    <LanguageSelector />
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
