import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import SignUp from "./pages/signup/Signup";
import Layout from "./component/Layout";
import Login from "./pages/login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import ErrorInterceptor from "./interceptores/ErrorInterceptor";
import TokenInterceptor from "./interceptores/TokenInterceptor";

import "./App.css";
import Dashboard from "./pages/dashboard/Dashboard";
import TripsControl from "./pages/trips/TripsControl";
import { FadeLoader } from "react-spinners";
import TripsDetails from "./pages/tripDetails/TripsDetails";
import UpdateTrip from "./component/updateUser/UpdateTrip";
import AddLanguage from "./component/addLanguage/AddLanguage";
import AddedTrips from "./component/addedTrips/AddedTrips";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="services/:servicesId" element={<TripsDetails />} />
        <Route path="contact" element={<Contact />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/dashboard" element={<Dashboard />}>
        <Route exact path="tripsControl" element={<TripsControl />} />
        <Route path="tripsControl/:id" element={<UpdateTrip />} />
        <Route path="addLanguage/:id" element={<AddLanguage />} />
        <Route path="addedTrips" element={<AddedTrips />} />
      </Route>
    </>
  )
);

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="app">
      {loading ? (
        <div className="spinner-container">
          <FadeLoader color="#2C834E" />
        </div>
      ) : (
        <>
          <ToastContainer />
          <RouterProvider router={routes} />
        </>
      )}
    </div>
  );
}

export default App;
