import React, { useEffect, useState } from "react";
import Headpage from "../../component/Headpage";
import Headerimage from "../../assets/night.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./signup.css";
import { FadeLoader } from "react-spinners";

const isValidUserName = (name) => name.trim() !== "";
const isValidEmail = (email) => email.trim() !== "";
const isValidPassword = (password) => password.trim() !== "";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { t } = useTranslation();

  const submit = async (e) => {
    e.preventDefault();

    const usernameIsValid = isValidUserName(username);
    const emailIsValid = isValidEmail(email);
    const passwordIsValid = isValidPassword(password);

    setUsernameError(usernameIsValid ? "" : "Please Enter Your User Name !");
    setEmailError(emailIsValid ? "" : "Please Enter Your Email !");
    setPasswordError(passwordIsValid ? "" : "Please Create Your Password !");

    if (usernameIsValid && passwordIsValid && emailIsValid) {
      try {
        const response = await axios.post(
          "https://tourism-api.basilalasadi.com/api/Authenticate/AddNewTechnician",
          {
            Username: username,
            Email: email,
            Password: password,
          }
        );
        if (response.data.success) {
          toast.success("تم انشاء الحساب ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          setUsername("");
          setEmail("");
          setPassword("");
        } else {
          setError(response.data.Message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <Headpage title="Sign Up" image={Headerimage}></Headpage>
      <section className="signup">
        {loading ? (
          <div className="spinner-container">
            <FadeLoader color="#2C834E" />
          </div>
        ) : (
          <div className="container signup-container">
            <form onSubmit={submit}>
              <div className="input-box">
                <input
                  type="text"
                  placeholder={t("signPage.userName")}
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                {usernameError && (
                  <p className="text-danger">{usernameError}</p>
                )}
              </div>
              <div className="input-box">
                <input
                  type="email"
                  placeholder={t("signPage.email")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </div>
              <div className="input-box">
                <input
                  type="password"
                  placeholder={t("signPage.password")}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                {passwordError && (
                  <p className="text-danger">{passwordError}</p>
                )}
              </div>
              <button disabled={loading} className="details-btn">
                {loading ? t("signPage.signingUp") : t("signPage.signUp")}
              </button>
            </form>
          </div>
        )}
      </section>
    </>
  );
};

export default Signup;
