export const about = [
  {
    id: 1,
    title: "Cairo Museum and the Pyramids full day",
    desc: "A trip to the pyramids is a journey back in time to one of the greatest civilizations in history. You'll stand in awe of the majestic Giza Pyramids, including the Great Pyramid, the largest pyramid ever built.  Explore the mysterious Sphinx, marvel at engineering feats accomplished thousands of years ago, and learn about the pharaohs entombed within these monuments.  Your trip can also include a camel ride across the desert sands for a truly unforgettable experience.",
    image1: require("./assets/pyramids-giza.jpg"),
    image2: require("./assets/Musium.jpg"),
  },
  {
    id: 2,
    title: "Safari in the Sinai Desert",
    desc: "Immerse yourself in the rugged beauty of the Sinai Desert on a safari adventure. Trade the beach for golden sands and dramatic canyons. Explore by jeep or camel, traversing through sun-baked landscapes and colored rock formations.",
    image1: require("./assets/sinia.webp"),
    image2: require("./assets/OIP.jpg"),
  },
  {
    id: 3,
    title: "Sharm Elshiekh",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima impedit, error reprehenderit aperiam illo illum possimus a quidemaspernatur modi. Lorem ipsum dolor sit amet consectetur adipisicing elit. Minimaimpedit, error reprehenderit aperiam illo illum possimus a quidem aspernatur modi. impedit, error reprehenderit aperiam illo illum possimus a quidemaspernatur modi. Lorem ipsum dolor sit amet consectetur adipisicing elit. Minimaimpedit, ",
    image1: require("./assets/image9.jpg"),
    image2: require("./assets/image7.jpg"),
  },
];

export const trips = [
  {
    id: 1,
    image: require("./assets/image6.jpg"),
    title: "Cairo Museum and the Pyramids full day",
    desc: "A trip to the pyramids is a journey back in time to one of the greatest civilizations in history. You'll stand in awe of the majestic Giza Pyramids, including the Great Pyramid, the largest pyramid ever built.",
    button: "Details",
    animation: "fade-up",
    animationOffset: "100",
    dataAosDelay: "200",
    dataAosDuration: "900",
  },
  {
    id: 2,
    image: require("./assets/image11.jpg"),
    title: "Safari in the Sinai Desert",
    desc: "Immerse yourself in the rugged beauty of the Sinai Desert on a safari adventure. Trade the beach for golden sands and dramatic canyons. Explore by jeep or camel, traversing through sun-baked landscapes and colored rock formations.",
    button: "Details",
    animation: "fade-up",
    animationOffset: "100",
    dataAosDelay: "300",
    dataAosDuration: "900",
  },
  {
    id: 3,
    image: require("./assets/image16.jpg"),
    title: "One day boat diving trip",
    desc: "Immerse yourself in an underwater world on a one-day boat diving trip. Cruise crystal-clear waters to explore vibrant coral reefs teeming with colorful fish. Dive into a world of tranquility and marvel at unique marine life.",
    button: "Details",
    animation: "fade-up",
    animationOffset: "100",
    dataAosDelay: "400",
    dataAosDuration: "900",
  },
];
