import React, { useEffect, useState } from "react";
import Headerhome from "../Headerhome";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Image1 from "../../assets/image6.jpg";
import Image2 from "../../assets/OIP.jpg";
import Image3 from "../../assets/image16.jpg";
import "./trips.css";
import { FadeLoader } from "react-spinners";

const Trips = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Aos.init();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <section className="trips">
      <Headerhome title={t("headerHomeTrips.title")}>
        {t("headerHomeTrips.paragraph")}
      </Headerhome>
      {loading ? (
        <div className="spinner-container">
          <FadeLoader color="#2C834E" />
        </div>
      ) : (
        <div className="container trips-container">
          <div
            className="card"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="200"
            data-aos-duration="900"
          >
            <img src={Image1} className="card-img-top" alt={"recentTrips"} />
            <div className="card-body">
              <h3 className="card-title">{t("recentTrips.trip1.title")}</h3>
              <p className="card-text">{t("recentTrips.trip1.description")}</p>
            </div>
            <a href="/#" className="details-btn">
              {t("recentTrips.trip1.button")}
            </a>
          </div>
          <div
            className="card"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="300"
            data-aos-duration="900"
          >
            <img src={Image2} className="card-img-top" alt={"recentTrips"} />
            <div className="card-body">
              <h3 className="card-title">{t("recentTrips.trip2.title")}</h3>
              <p className="card-text">{t("recentTrips.trip2.description")}</p>
            </div>
            <a href="/#" className="details-btn">
              {t("recentTrips.trip2.button")}
            </a>
          </div>
          <div
            className="card"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="400"
            data-aos-duration="900"
          >
            <img src={Image3} className="card-img-top" alt={"recentTrips"} />
            <div className="card-body">
              <h3 className="card-title">{t("recentTrips.trip3.title")}</h3>
              <p className="card-text">{t("recentTrips.trip3.description")}</p>
            </div>
            <a href="/#" className="details-btn">
              {t("recentTrips.trip3.button")}
            </a>
          </div>
        </div>
      )}
    </section>
  );
};

export default Trips;
