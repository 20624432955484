import React, { useEffect, useState } from "react";
import Headerpage from "../../component/Headpage";
import contactImage from "../../assets/2.jpg";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import "./contact.css";
import { FadeLoader } from "react-spinners";

const Contact = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      <Headerpage
        title={t("headerHomeContact.title")}
        image={contactImage}
      ></Headerpage>
      <section className="contact pt-5 pb-5">
        {loading ? (
          <div className="spinner-container">
            <FadeLoader color="#2C834E" />
          </div>
        ) : (
          <div className="container">
            <h1 className="text-center fw-bold pb-5">
              {t("headerHomeContact.paragraph")}
            </h1>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <div className="icon-box">
                    <MdEmail />
                  </div>
                  <div className="info-box">
                    <h3>{t("contactPage.email")}</h3>
                    <p>salahmobark73@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <div className="icon-box phone">
                    <FaPhoneAlt />
                  </div>
                  <div className="info-box">
                    <h3>{t("contactPage.callUs")}</h3>
                    <p> +201151115204</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <div className="icon-box location">
                    <HiLocationMarker />
                  </div>
                  <div className="info-box">
                    <h3>{t("contactPage.reachUs")}</h3>
                    <p>Sharm El Sheik, Egypt</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Contact;
