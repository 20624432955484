import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Topbar from "../topbar/Topbar";
import "./addedTrips.css";

const AddedTrips = () => {
  const [addedTrips, setAddedTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otherLanguageData, setOtherLanguageData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || languages[0].code
  );

  const languages = [
    { code: "EN", lang: "English", Id: 1 },
    { code: "IT", lang: "Italy", Id: 2 },
  ];

  const fetchOtherLanguageData = async () => {
    try {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      const response = await axios.get("https://tourism-api.basilalasadi.com/api/Languages");
      setOtherLanguageData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  const getAllAddedTrips = async () => {
    try {
      const selectedLanguageObject = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (!selectedLanguageObject) {
        console.error("Selected language not found in languages list.");
        return;
      }
      const response = await axios.post(
        "https://tourism-api.basilalasadi.com/api/Trip/SearchTrips",
        { languageId: selectedLanguageObject.Id }
      );
      setAddedTrips(response.data.Items);
    } catch (error) {
      console.error("Error sending language code:", error);
    }
  };

  const handleDeleteRequest = async (trip) => {
    Swal.fire({
      title: "Are You Sure!",
      text: "You Won't be able to retrieve the Trip!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      backdrop: `rgba(0,0,123,0.4)`,
      customClass: {
        container: "my-swal",
      },
    }).then(async (willDelete) => {
      if (willDelete.value) {
        try {
          const parsedData = JSON.parse(JSON.stringify(trip));
          const TripId = parsedData.TripId;
          await axios.delete(`https://tourism-api.basilalasadi.com/api/Trip/trips/${TripId}`);
          Swal.fire({
            title: "Deleted!",
            text: "The AC type has been deleted successfully",
            icon: "success",
            confirmButtonText: "Cool",
            timer: 2000,
          });
          getAllAddedTrips();
        } catch (error) {
          // Extract the error message from the response object
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
            console.log(errorMessage);
          } else {
            errorMessage = "An unexpected error occurred";
            console.log(errorMessage);
          }
          // Display the error message using SweetAlert2
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Try Again",
          });
          setError(error.response?.data || {});
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your AC type is safe :)",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
  };

  useEffect(() => {
    fetchOtherLanguageData();
    getAllAddedTrips();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <section className="addedTrips">
      <Topbar />
      <div className="addedTrips-container container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mt-5">
            <h2 className="mb-5">Our Trips:</h2>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Location</th>
                    <th scope="col">Description</th>
                    <th scope="col">Cost</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col" className="text-center">
                      Control
                    </th>
                  </tr>
                </thead>
                {addedTrips?.length > 0 ? (
                  <tbody>
                    {addedTrips.map((trip) => (
                      <tr key={trip.TripId}>
                        <td>{trip.Title}</td>
                        <td>{trip.Location}</td>
                        <td>{trip.Description}</td>
                        <td>{trip.CostPerPerson}$</td>
                        <td>{trip.FromDate}</td>
                        <td>{trip.ToDate}</td>
                        <td className="btn-box">
                          <button onClick={() => handleDeleteRequest(trip)}>
                            Delete
                          </button>
                          <Link to={`/dashboard/tripsControl/${trip.TripId}`}>
                            <button>Edit</button>
                          </Link>
                          <Link to={`/dashboard/addLanguage/${trip.TripId}`}>
                            <button>Add Language</button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="no-data-message">
                      <td colSpan="7">No Added Trips</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddedTrips;
